<template>
  <div class="row">
    <div class="col-12">
      <div class="bg-light p-5 b-30 shadow-1">
        <div v-if="loading">
          <sk-list :items-count="2" :height="0.04"></sk-list>
        </div>
        <div v-else>
          <div v-if="!rules || accepted" class="row">
            <div class="col-12">
              <h3>{{ $t('clubs.club-information') }}</h3>
            </div>
           
            
            <div class="col-12 col-md-6 col-lg-4 offset-md-3 offset-lg-4">
              <div class="form-group">
                <label for="name">{{ $t('clubs.name') }}</label>
                <input v-model="form.name" id="name" type="text" class="form-control" />
              </div>
              <h3>{{ $t('clubs.head-info') }}</h3>
              
              <div class="form-group"   >
                <label for="headPersonalId">{{ $t('clubs.personal-id') }}</label>
                <input v-model="form.head.personal_id" id="headPersonalId" type="text" class="form-control" />
              </div>
              <div class="form-group">
                <label for="headMobile">{{ $t('clubs.mobile') }}</label>
                <input v-model="form.head.mobile" id="headMobile" type="text" class="form-control" />
              </div>
              <div class="form-group">
                <label for="headEmail">{{ $t('clubs.email') }}</label>
                <input v-model="form.head.email" id="headEmail" type="text" class="form-control" />
              </div>


              <h3   v-if="showFields"  >
                {{ $t('clubs.members') }}
                <button @click="addMember()" class="btn btn-primary-red-7 float-right">+ დამატება</button>
              </h3>

              <!-- {{ form.personal_ids.includes(form.head.personal_id) }} -->

             

              <div    v-if="showFields"  >
              <div  v-for="(personalId, $i) of form.personal_ids" class="form-group position-relative"  >
                <label :for="'memberPersonalId' + $i">{{ $t('clubs.personal-id') }}</label>
                <input  v-model="form.personal_ids[$i]" :id="'memberPersonalId' + $i" type="text" class="form-control" />
                <button @click="removeMember($i)" class="btn btn-primary-red-7 btn-delete">-</button>
              </div>
              </div>
            </div>
            <div class="col-12 col-lg-8 offset-lg-2">
              <h3>{{ $t('clubs.club-info') }}</h3>
              <div class="form-group">
                <label for="goal">{{ $t('clubs.goal') }}</label>
                <textarea v-model="form.goal" id="goal" class="form-control" />
              </div>
              <div class="form-group">
                <label for="governance_rules">{{ $t('clubs.governance-rules') }}</label>
                <textarea v-model="form.governance_rules" id="governance_rules" class="form-control" />
              </div>
              <div class="form-group">
                <label for="membership">{{ $t('clubs.membership') }}</label>
                <textarea v-model="form.membership" id="membership" class="form-control" />
              </div>
              <div class="form-group">
                <label for="other_information">{{ $t('clubs.other-information') }}</label>
                <textarea v-model="form.other_information" id="other_information" class="form-control" />
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 offset-md-3 offset-lg-4">
              <b-form-file
                v-model="form.file"
                :placeholder="$t('clubs.choose-logo')"></b-form-file>
            </div>
            <div class="col-12 text-center mt-5">
              <button @click="handleSave()" class="btn btn-primary-red">{{ $t('clubs.save') }}</button>
            </div>
          </div>
          <div v-else class="row text-center">
            <div class="col-12 mb-3">
              <h3>{{ $t('clubs.rules') }}</h3 >
            </div>
            <div class="col-12 font-16" v-html="rules"></div>
            <div class="col-12 text-center mt-5">
              <input id="accept" v-model="accepted" :value="1" type="checkbox" />
              <label for="accept">{{ $t('clubs.accept') }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClubResource from '../../api/clubResource';
import SkList from '../skeletons/sk-list';
import {mapGetters} from 'vuex'

const resource = new ClubResource()

export default {
  name: 'club-form',

  components: { SkList },

  data() {
    return {
      loading: false,
      form: this.emptyForm(),
      rules: null,
      accepted: false,
      showFields:true
    }
  },

  mounted() {
    this.loadClubDetails()
  },

 watch:{
  form:{
     
      handler:function(pn){

         
         
        if(pn.head.personal_id.length==11){
          
            if(pn.head.personal_id==this.$store.state.auth.user.personal_code){
              
              this.showFields=true

            }else {
               
              this.showFields=false
              
            }

        }else {
         
          this.showFields=true
        }

      },
      deep:true
     
     

  },
  showFields(val){

    if(!val){
      this.form.personal_ids=[]
    }

  }
 },

  methods: {
    handleSave() {
      const formData = new FormData()
      formData.append('name', this.form.name)
      formData.append('goal', this.form.goal)
      formData.append('governance_rules', this.form.governance_rules)
      formData.append('membership', this.form.membership)
      formData.append('other_information', this.form.other_information)
      formData.append('head[personal_id]', this.form.head.personal_id)
      formData.append('head[mobile]', this.form.head.mobile)
      formData.append('head[email]', this.form.head.email)
      for(const pId of this.form.personal_ids)
        formData.append('personal_ids[]', pId);

      if (this.form.file)
        formData.append('file', this.form.file)

     

      if(this.form.id)
        this.update(formData)
      else
        this.create(formData)
    },




    create(formData) {
      resource.createClub(formData)
      .then(resp => {
        this.helpers.notifySuccessMessage(this.$t('clubs.successfully-created'))
        this.loadClubDetails()
      })
      .catch(err => {
        this.helpers.notifyErrorMessage(err)
        setTimeout(()=>{

         // window.location.href='/clubs'

        },2000)
      })
    },

    update(formData) {
      resource.updateClub(this.form.id, formData)
        .then(resp => {
          this.helpers.notifySuccessMessage(this.$t('clubs.successfully-updated'))
          this.loadClubDetails()
        })
        .catch(err => {
          this.helpers.notifyErrorMessage(err)
        })
    },

    addMember() {
      this.form.personal_ids.push('')
    },

    removeMember(i) {
      this.form.personal_ids.splice(i, 1)
    },

    fillForm(data) {
      this.form.id = data.self_governmentID
      this.form.name = data.club_name
      this.form.personal_ids = data.personal_ids
      this.form.head.personal_id = data.personal_ids[0]
      this.form.head.mobile = data.mob
      this.form.head.email = data.email
      this.form.goal = data.club_goals
      this.form.governance_rules = data.manager
      this.form.membership = data.cancellation
      this.form.other_information = data.more
    },

    emptyForm() {
      return {
        id: null,
        name: '',
        personal_ids: [''],
        head: {
          personal_id: '',
          mobile: '',
          email: ''
        },
        goal: '',
        governance_rules: '',
        membership: '',
        other_information: '',
        file: null,
      }
    },

    loadClubDetails() {
      this.loading = true;
      resource.clubDetails()
      .then(resp => {
        const data = resp.data.data
        if(data.myClub) this.fillForm(data.myClub)
        else this.loadRules()
      })
      .finally(() => {
        this.loading = false
      })
    },

    loadRules() {
      resource.rules()
      .then(resp => {
        this.rules = resp.data.data.rules
      })
    }
  }
};
</script>
<style scoped>
.btn-delete {
  position: absolute;
  top: 20px;
  right: 0;
  height: 31px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
</style>
